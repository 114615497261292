






















import Uppy, { UploadResult, UppyFile } from '@uppy/core';
import { inject } from 'inversify-props';
import { Vue, Component, Prop, VModel, Watch } from 'vue-property-decorator';
import XHRUpload from '@uppy/xhr-upload';
import { random } from 'lodash';
import { Dashboard } from '@uppy/vue';
import { DashboardOptions } from '@uppy/dashboard';
import PtBr from '@uppy/locales/lib/pt_BR';
import SessionService from '@/services/session.service';
import { InjectionIdEnum } from '@/enums/injection-id.enum';
import dayjs from '@/plugins/dayjs';
import { IUploadedFile } from '@/interfaces/uploaded-file.interface';
import { IKeyValue } from '@/interfaces/key-value.interface';
import { ClientTypeEnum } from '@/enums/client-type.enum';

@Component({
  components: {
    Dashboard,
  },
})
export default class DashboardUpload extends Vue {
  @inject(InjectionIdEnum.SessionService)
  protected sessionService!: SessionService;

  uppyInstance: Uppy | null = null;

  showUploader = false;

  @VModel()
  dialog!: boolean;

  @Watch('dialog')
  onModelChange(active: boolean): void {
    if (active) {
      if (!this.uppyInstance) {
        this.uppyInstance = this.getUppyInstance();
      }

      this.uppyInstance.setMeta({
        clientId: this.clientId,
        clientType: this.clientType,
        place: this.place || '',
        hash: this.hash || '',
      });

      this.showUploader = true;
    } else {
      setTimeout(() => {
        this.showUploader = false;
      }, 450);
    }
  }

  @Prop({ required: true })
  uploadEndpoint!: string;

  @Prop()
  enableDescription!: boolean;

  @Prop({ required: true })
  clientId!: string;

  @Prop({ required: true })
  clientType!: ClientTypeEnum;

  @Prop()
  place!: string;

  @Prop()
  hash!: string;

  @Prop()
  title!: string;

  afterClose(): void {
    this.$emit('after-close');
  }

  onClose(): void {
    this.dialog = false;
  }

  onCancel(): void {
    this.uppyInstance?.cancelAll();
    this.dialog = false;
  }

  beforeDestroy(): void {
    if (this.uppyInstance) {
      this.uppyInstance.close();
      this.uppyInstance = null;
    }
  }

  get dashboardProps(): DashboardOptions {
    return {
      inline: true,
      width: '100%',
      height: 'calc(100vh - 150px)',
      proudlyDisplayPoweredByUppy: false,
      metaFields: this.enableDescription ? [{ id: 'description', name: 'Descrição', placeholder: '' }] : [],
      hideRetryButton: true,
    };
  }

  get dialogTitle(): string {
    return this.title || `${this.$t('global.dashboardUpload.title')}`;
  }

  private getUppyInstance(): Uppy {
    const uppy = new Uppy({
      locale: PtBr,
      id: `${dayjs().format()}${random(1, 15)}`,
      autoProceed: false,
    })
      .use(XHRUpload, {
        id: `${dayjs().format()}${random(1, 15)}`,
        endpoint: this.uploadEndpoint,
        headers: {
          Authorization: `Bearer ${this.sessionService.apiToken}`,
        },
        responseType: 'text',
        method: 'POST',
        fieldName: 'files',
        formData: true,
        bundle: true,
        timeout: 0,
        getResponseError: (responseText: string) => {
          let errorMessage = responseText;
          if (!responseText) {
            errorMessage = `${this.$t('global.dashboardUpload.errorOnUpload')}`;
          }
          return new Error(errorMessage);
        },
      })
      .on('upload-error', this.onUploadError)
      .on('complete', this.onUploadComplete);

    return uppy;
  }

  private onUploadComplete(result: UploadResult): void {
    const files: IUploadedFile[] = [];

    result.successful.forEach((file) => {
      const { id, name, size } = file;

      if (!files.some((x) => x.uploadId === id)) {
        const body = file.response?.body || {};
        const { filename, path } = ((body.files || []) as Array<IKeyValue>).find((x) => x.filename === name) || {};

        const fileData: IUploadedFile = {
          uploadId: id,
          filename,
          path,
          description: file.meta?.description as string,
          parsedSize: DashboardUpload.parseSize(size),
        };
        files.push(fileData);
      }

      this.uppyInstance?.removeFile(file.id);
    });

    this.$notify.success(this.$t('global.dashboardUpload.uploadSuccessfulyFinished'), 1000);

    this.uppyInstance?.reset();
    this.uppyInstance?.resetProgress();

    this.$emit('upload-files', files);
  }

  private onUploadError(file: UppyFile, error: Error): void {
    this.$notify.error(error);
  }

  static parseSize(size: number): string {
    let parsedSize = '';
    const mbFactor = 1048576;
    const kbFactor = 1024;

    if (size >= mbFactor) {
      parsedSize = `${Math.round(size / mbFactor)}MB`;
    } else if (size >= kbFactor) {
      parsedSize = `${Math.round(size / kbFactor)}KB`;
    } else {
      parsedSize = `${size}bytes`;
    }

    return parsedSize;
  }
}
